// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { Environment } from 'src/app/shared/models/environment.interface';
import { baseEnvironment } from 'src/environments/baseEnvironment';

export const environment: Environment = {
	...baseEnvironment,
	requestDatasheetUrl: 'https://merlo-sav.datalean-nodejs-dev.catalean.com/merlo-sav/',
	organizationPrefix: 'MER',
	organizationUUID: '88811bb2-020a-4b4d-b400-13efabff1ace',
	activeLanguage: 'it-IT',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related message stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an message is thrown.
 */
// import 'zone.js/plugins/zone-message';  // Included with Angular CLI.
